.home__section {
    display: flex;
    padding: 30px;
  }

.home__intro {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    justify-content: center;
}

.home__card_no_animation {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
}

.home__intro_text_container {/* contains a big block of text about overall purpose of the site, on top of bcg img*/
    position: absolute;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    justify-content: center;
    top: 10%;
}

.home__intro__text {
    max-width: max-content;
    text-align: center!important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.home__intro_img {
    object-fit: none;
}

.home__savings_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    color: #fff;
    text-align: center;    text-transform: uppercase;
    text-shadow: 1px 1px 0 rgba(0,0,0,.75);
    -webkit-transform: translate3d(-50%,-50%,0);
    -moz-transform: translate3d(-50%,-50%,0);
    -ms-transform: translate3d(-50%,-50%,0);
    -o-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
}

.home_savings_button_box { /* a bordered box containing input box and a show me saving button*/
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    height: 45px;
    border: 0px solid lightgray;
    border-radius: 8px;
}

.home_savings_input { /* input filed for property price*/
    width: 60%;
    height: 30px;
    font-size: 1rem;
    border-width: 0;
    border-radius: 4px;
}

.home_get_saving_button {
    background-color: #1C85E8;
    height: 30px;
    width: 35%;
    border-width: 0;
    border-radius: 4px;
    font-size: 1rem;
}